
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import axios from "axios";

export default defineComponent({
  name: "overview",
  components: {},
  data(): {
    ApiURL: string;
    startDate: string;
    endDate: string;
    Step: number;
    Modal: any;
    SpecList: Array<any>;
    Model_Id: any;
    SpecItem: any;
    SpecType: any;
  } {
    return {
      ApiURL: "https://ah.artinuro.com:8443/",
      startDate: "",
      endDate: "",
      Step: 1,
      Modal: {},
      SpecList: [],
      Model_Id: {},
      SpecItem: {},
      SpecType: 0,
    };
  },
  methods: {
    newGuid() {
      var guid = "";
      for (var i = 1; i <= 32; i++) {
        var n = Math.floor(Math.random() * 16.0).toString(16);
        guid += n;
        if (i == 8 || i == 12 || i == 16 || i == 20) guid += "-";
      }
      return guid;
    },
    async Init() {
      this.Modal = {
        Id: this.newGuid(),
        Name: "",
        No: "",
        IQCs: [],
        ModelSpecs: [],
      };
    },
    async SaveModal() {
      axios
        .post(this.ApiURL + "Model", this.Modal)
        .then(res => {
          alert("新增成功");
          this.GetSpecList(this.Modal.Id);
        })
        .catch(err => {
          console.log(err);
        });
    },
    async GetSpecList(id) {
      this.Model_Id = id;
      axios
        .post(this.ApiURL + "ModelSpec/List/" + id, {
          Paging: {
            pageSize: 999,
            pageNumber: 1,
          },
          Search: {},
        })
        .then(res => {
          this.SpecList = res.data;
          this.Step = 2;
        })
        .catch(err => {
          console.log(err);
        });
    },
    async GetSpec(id) {
      axios
        .get(this.ApiURL + "ModelSpec/" + id)
        .then(res => {
          this.SpecItem = res.data;
          this.SpecType = 0;
        })
        .catch(err => {
          console.log(err);
        });
    },
    async GetNewSpec() {
      this.SpecItem = {
        Id: this.newGuid(),
        Upper: 0,
        Lower: 0,
        Model_Id: this.Modal.Id,
        Item: "",
        Item_No: "",
        Sheet_No: "",
        Importance: "",
        Inspection_Method: "",
        Specification: 0,
        Unit: "",
        Mark: false,
        Enable: false,
        Removed: false,
        Process: "",
        IQCDetails: [],
        Model: null,
      };
      this.SpecType = 1;
      console.log(this.SpecType);
    },
    async SaveSpec() {
      switch (this.SpecType) {
        case 0:
          console.log("put");
          axios
            .put(this.ApiURL + "ModelSpec", this.SpecItem)
            .then(res => {
              console.log(res);
              alert("修改成功");
              this.GetSpecList(this.SpecItem.Model_Id);
            })
            .catch(err => {
              console.log(err);
            });
          break;

        case 1:
          console.log("post");
          axios
            .post(this.ApiURL + "ModelSpec", this.SpecItem)
            .then(res => {
              console.log(res);
              alert("新增成功");
              this.GetSpecList(this.SpecItem.Model_Id);
            })
            .catch(err => {
              console.log(err);
            });
          break;
      }
    },
    async DeleteSpec(item) {
      var yes = confirm("請確定是否刪除");
      if (yes) {
        axios
          .put(this.ApiURL + "ModelSpec/Removed", item)
          .then(res => {
            console.log(res);
            alert("刪除成功");
            this.GetSpecList(this.Model_Id);
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
  },
  created() {
    this.Init();

    onMounted(() => {
      setCurrentPageBreadcrumbs("新增料貨號", ["料貨號管理"]);
    });
  },
  // setup() {},
});
